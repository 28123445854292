import React, { useEffect, useState } from 'react'
import {doc , addDocs, collection, getDocs, orderBy, query, updateDoc  } from 'firebase/firestore'

import { db } from '../firebase'
import youtubeapp from '../Images/1red-and-white.png'
import {useSelector} from 'react-redux';
import {selectUsers} from '../redux/usersSlice';

export default function YtReadtabs() {
  const user = useSelector(selectUsers);

    let currentUser = `${user.currentUser.id}`

    const[Name] = useState()
    const[discord] = useState()
    const[subscribers] = useState()
    const[fetchData, setFetchData] = useState([])
    const propertyName = `${user.currentUser.id}`;

    const dbbref = collection(db, "applications_yt");
    const q = query(dbbref, orderBy('timestamp', 'desc'))
    

    // Fetching Data from FireStore

    const fetch = async () =>
    {
        const snapshot = await getDocs(q)
        const fetchdata = snapshot.docs.map((doc => ({id: doc.id, ...doc.data()})))
        setFetchData(fetchdata)
        
    }

    useEffect(() =>
    {
        fetch()
    },[]
    )

  return (
    <div>
    <h1 className='dh1'>Youtube Rank Applications</h1>
    <div className="grid lg:grid-cols-4 sm:grid-cols-1">
        {
            fetchData.map((data)=> 
            {
               
              
              const propUser = data ? data[propertyName] : false;
                
              const markread = async () =>
              {
                  const readRef = doc(db, 'applications_yt', `${data.id}`);
                  updateDoc(readRef, { [currentUser]: true });
                  fetch()
              }
                return(
                 <>
                  { propUser ? 
                    <>
                    <div className="database">
                       
                    
                    <div className="max-w-72 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">                        <img className="rounded-t-lg container max-w-screen-lg mx-auto flex justify-center" src={youtubeapp} alt="" />

                    <div className="p-5">
                    <div className='flex'><h5 class="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">{data.name}</h5><svg className='ml-2 mt-1' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" id="double-check"><path fill="#fcd34d" fill-rule="evenodd" d="M16.5303 6.46967C16.8232 6.76256 16.8232 7.23744 16.5303 7.53033L6.53033 17.5303C6.38968 17.671 6.19891 17.75 6 17.75 5.80109 17.75 5.61032 17.671 5.46967 17.5303L1.46967 13.5303C1.17678 13.2374 1.17678 12.7626 1.46967 12.4697 1.76256 12.1768 2.23744 12.1768 2.53033 12.4697L6 15.9393 15.4697 6.46967C15.7626 6.17678 16.2374 6.17678 16.5303 6.46967zM22.5303 6.46966C22.8232 6.76254 22.8232 7.23742 22.5303 7.53032L12.5308 17.5303C12.2379 17.8232 11.7631 17.8232 11.4702 17.5304L9.96975 16.0304C9.67681 15.7376 9.67674 15.2627 9.96959 14.9697 10.2624 14.6768 10.7373 14.6767 11.0303 14.9696L12.0004 15.9394 21.4697 6.46968C21.7625 6.17678 22.2374 6.17677 22.5303 6.46966z" clip-rule="evenodd"></path></svg></div>
                        <p className="mb-3 text-sm font-normal text-gray-700 dark:text-gray-400">Discord Name: {data.discord}</p>
                        <p className="mb-3 text-sm font-normal text-gray-700 dark:text-gray-400">Subscribers: {data.subscribers}</p>
                        <button class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="submit" onClick={() => {window.location.replace(`/thread/${data.id}`)}} >Open</button>
                        
                        </div>
                        
                      <div>
                        </div>  
                              </div>
                              
    </div>


                    </> : <></>
                }
                </>  
                        

                )
                
            })
            
        }
      </div>
  




    </div>
  )
}
