import React, { useEffect, useState } from 'react'
import {doc , getDoc, updateDoc  } from 'firebase/firestore'
import { db } from '../firebase'
import { useNavigate, useParams } from 'react-router-dom'
import {useSelector} from 'react-redux';
import {selectUsers} from '../redux/usersSlice';




function Thread() {
  const user = useSelector(selectUsers);

  let currentUser = `${user.currentUser.id}`
    const {id} = useParams()
    const[data, setData] = useState([])


    const fetchData  = async () => {
    const docRef = doc(db, "applications_yt", id);
    const docSnap = await getDoc(docRef)
    if (docSnap.exists) {
        setData(docSnap.data());
    }
    
    
    }
    useEffect(() =>
    {
        fetchData()
    },[]
    )
    const markread = async () => {
      {
                  const readRef = doc(db, 'applications_yt', id);
                  updateDoc(readRef, { [currentUser]: true });
                  
            
              }
  }

    
    
    return (
      
                 <>
    <div  className="flex flex-col items-center pt-6 px-6 py-8 mx-auto md:h-screen lg:py-0">
      <a href="#"  className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
          <img  className="w-8 h-8 mr-2" src="https://cdn.discordapp.com/attachments/762622478173601812/1083813904280465418/logo.png?ex=65fdc95b&is=65eb545b&hm=5644e52c65370f85bd970915112e56f5f7e0a09f0766a8d1421a602fe45343c7&" alt="logo"/>
          <img  className="" src="https://cdn.discordapp.com/attachments/762622478173601812/985465595837423626/Website.png?ex=65ffe8db&is=65ed73db&hm=0d80a0adbbc156b1ef04022dbaa91b538b9f0b3ad8e883e099db6718f3f0fa22&" alt="logo"/>
      </a>
      <div  className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-5xl xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div  className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className='nq'>Name:</h1> <h1 className='nr px-4 pt-1'>{data.name}</h1>
            <h1 className='nq'>Age:</h1> <h1 className='nr px-4 pt-1'>{data.age}</h1>
            <h1 className='nq'>Discord Name:</h1> <h1 className='nr px-4 pt-1'>{data.discord}</h1>
            <h1 className='nq'>Minecraft Name:</h1> <h1 className='nr px-4 pt-1'>{data.mcuname}</h1>
            <h1 className='nq'>Language(s):</h1> <h1 className='nr px-4 pt-1'>{data.lang}</h1>
            <h1 className='nq'>Why should we consider you:</h1> <h1 className='nr px-4 pt-1'>{data.why_should_we_consider_you}</h1>
            <h1 className='nq'>Have you ever ben punished on HavenLands:</h1> <h1 className='nr px-4 pt-1'>{data.punished}</h1>
            <h1 className='nq'>Link to your channel:</h1> <h1 className='nr px-4 pt-1'>{data.channel}</h1>
            <h1 className='nq'>How many subscribers do you have:</h1> <h1 className='nr px-4 pt-1'>{data.subscribers}</h1>
            <button class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="submit" onClick={() => {window.location.replace("/applications")}} >Back</button>
            <button class="inline-flex items-center ml-3 px-3 py-2 text-sm font-medium text-center text-white bg-amber-300 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-amber-500 dark:hover:bg-amber-700 dark:focus:ring-amber-800" type="submit" onClick={() => {markread()  }} >Mark as Read </button>
          </div>
        </div>
      </div>
                        </>
                    )
            

          
      
    
    
    
    
     
    }
    

export default Thread

