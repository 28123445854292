import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import 'firebase/compat/firestore'
import { getAuth } from "firebase/auth"
import firebase from 'firebase/compat/app'



const firebaseConfig = {
  apiKey: "AIzaSyCj3hwaKeKTVoOxCWAHSDZZoQjo1VlfrZM",
  authDomain: "havenlands-224db.firebaseapp.com",
  projectId: "havenlands-224db",
  storageBucket: "havenlands-224db.appspot.com",
  messagingSenderId: "133402476651",
  appId: "1:133402476651:web:4cc6f7abd79ce1d21bf12e",
  measurementId: "G-1Z5H5WW1QS"
};



// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore()

 

export const auth = getAuth();

export default app;
