import React, { useEffect, useState } from 'react'
import Navbar from '../Components/Navbar'
import ServerStatus from '../Components/ServerStatus'
import  ClipLoader from 'react-spinners/ClipLoader'
import logo from '../Images/logo.png'
import brandname from '../Images/BrandNameDesign.png'
import swal from 'sweetalert'
import {doc , getDoc } from 'firebase/firestore'
import { db } from '../firebase'

const Dashboard = () => {

  const[data, setData] = useState([])

  const fetchData  = async () => {
    const docRef = doc(db, "server-ips", "Xe9gtbp7XdGo5iqvJPoh");
    const docSnap = await getDoc(docRef)
    if (docSnap.exists) {
        setData(docSnap.data());
    }
    
    
    }
    useEffect(() =>
    {
        fetchData()
    },[]
    )



  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    setTimeout(() =>{
      setLoading(false)
    }, 0)
  }, [])

  

  
  return (
    
    <div>
      {
    loading ?
    <div className="loader">
    <ClipLoader color="#03e8fc"size={150}/> </div> :  
<div>
    <Navbar/>
    <section className="text-gray-600 body-font">
        <div className="container mx-auto flex px-5 items-center justify-center flex-col">
          <img
            className="w-48 mb-10 object-cover object-center rounded floting"
            alt="logo"
            src={logo}
          />
          <div className="text-center lg:w-2/3 w-full">
            <h1 className="relative bottom-8 title-font sm:text-2xl text-1xl font-bold uppercase Bold bg-clip-text drop-shadow-xl text-transparent text-white ">
              HavenLands
            </h1>
            <h1 onClick={()=>{navigator.clipboard.writeText("play.havenlands.online")
          swal({
            title: "IP Copied",
            text: "play.havenlands.online has been copied",
            icon: "success",
          })
          
          }} className=" cursor-pointer relative bottom-8 title-font sm:text-md text-md font-bold uppercase Bold bg-clip-text drop-shadow-xl text-transparent text-white ">
              play.Havenlands.online
            </h1>
          </div>
         </div>
        </section>
        <div className='grid grid-cols-3'>
        <a href="#" class="mx-8 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800/50 dark:border-gray-700">


        <h5 class="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">Server Status</h5>
            <p class="font-normal text-gray-700 dark:text-gray-400">
            <ServerStatus
              servers={[
                { name: 'Proxy', url: `${data.proxy}` },
                { name: 'Lobby', url: `${data.lobby}` },
                { name: 'Bedwars', url: `${data.bedwars}` },
                { name: 'Oneblock', url: `${data.oneblock}` },
                { name: 'Creative', url: `${data.creative}` },
                { name: 'Skyblock', url: `${data.skyblock}` }
              ]}
            />
            </p>
            </a>

<a href="#" class="mx-8 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800/50 dark:border-gray-700">

<h5 class="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">Player Statistics</h5>
<p class="font-normal text-gray-700 dark:text-gray-400"><li/><li/><li/><li/><li/></p>
</a>

<a href="#" class="mx-8 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800/50 dark:border-gray-700">

<h5 class="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">Staff Online</h5>
<p class="font-normal text-gray-700 dark:text-gray-400"><li/><li/><li/><li/><li/></p>
</a>

</div>

{/* <section className='h-screen backdrop-blur-md'>
<a href="#" class=" mx-auto mt-36 homep block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">

<h5 class=" mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Sorry!</h5>
<p class="font-normal text-gray-700 dark:text-gray-400">Home page is still under development :(</p>
<p class="font-normal text-gray-700 dark:text-gray-400">Untill use rest of the website.</p>
<br/>
<p class="font-normal text-gray-700 dark:text-gray-400">Thank You-</p>
</a>
</section> */}

    </div>

    
    
  }
    </div>
  );
};

export default Dashboard
