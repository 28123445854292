import React, { useEffect, useState } from 'react'
import Navbar from '../Components/Navbar'
import Ytapps from  '../Components/Ytapps'
import Staffapps from '../Components/Staffapps'
import  ClipLoader from 'react-spinners/ClipLoader'
import { Link, useParams } from 'react-router-dom';
import { browserHistory, Router, Route } from 'react-router';
import YtReadtabs from '../Components/YTReadtab'
import STAFFReadtab from '../Components/STAFFReadtab'

const Applications = (props) => {
  const {id} = useParams("unread")

  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    setTimeout(() =>{
      setLoading(false)
    }, 400)
  }, [])
    
return (  
  
<>

   <div>
  
  <div className='pb-3'>
  <Navbar page="Applications"/>
  

</div>
{
    id === "unread" 







    ?
    
    <div>
      <div className='float-right mr-20'>
          <Link
                    id="unread"
                    className={`mr-5 text-white hover:text-white px-2 py-1 rounded bg-slate-800 hover:bg-gray-300 transition-colors duration-100`}
                    to="/applications/unread">
                    Unread
          </Link>
              <Link
                  id="unread"
                  className={`mr-5 text-white hover:text-white px-2 py-1 rounded ${props.page === 'read' ? 'bg-slate-800 hover:bg-gray-300' : 'hover:bg-gray-800'} transition-colors duration-100`}
                  to="/applications/read">
                  Read
              </Link>
              </div>
     <Staffapps/>
      <Ytapps/>
      </div>
      
      
      
      
      
      
      
      
      
      : 
    id === "read" ? <>
<div className='float-right mr-20'>
              <Link
                    id="unread"
                    className={`mr-5 text-white hover:text-white px-2 py-1 rounded ${props.page === 'read' ? 'bg-slate-800 hover:bg-gray-300' : 'hover:bg-gray-800'} transition-colors duration-100`}
                    to="/applications/unread">
                    Unread
          </Link>
              <Link
                  id="unread"
                  className={`mr-5 text-white hover:text-white px-2 py-1 rounded bg-slate-800 hover:bg-gray-300 transition-colors duration-100`}
                  to="/applications/read">
                  Read
              </Link>
              </div>
       <STAFFReadtab/>
    <YtReadtabs/>
 
    </> : <></>

   

}
  </div>

    </>
        
  )
}

export default Applications

