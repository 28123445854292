import React, { useEffect, useState } from 'react'
import {doc , addDocs, collection, getDocs, orderBy, query } from 'firebase/firestore'
import { db } from '../firebase'
import Navbar from '../Components/Navbar'
import  ClipLoader from 'react-spinners/ClipLoader'

export default function Members() {
    // const [loading, setLoading] = useState(false)
    // useEffect(() => {
    //   setLoading(true)
    //   setTimeout(() =>{
    //     setLoading(false)
    //   }, 700)
    // }, [])

    const[fetchData, setFetchData] = useState([])

    const dbbref = collection(db, "users")
    const q = query(dbbref, orderBy("position"))

    // Fetching Data from FireStore

    const fetch = async () =>
    {
        const snapshot = await getDocs(q)
        const fetchdata = snapshot.docs.map((doc => ({id: doc.id, ...doc.data()})))
        setFetchData(fetchdata)
    }

    useEffect(() =>
    {
        fetch()
    },[]
    )
    
  
    return (
      <div><Navbar page="Members"/>
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        
      <div className="mx-auto mb-10 lg:max-w-xl sm:text-center">
        <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-white uppercase rounded-full bg-teal-accent-400">
          Members List
        </p>
      </div>
      <div className="grid gap-10 mx-auto lg:max-w-screen-lg sm:grid-cols-2 lg:grid-cols-3 md:grid-cols-2">
          {
            
              fetchData.map((data)=> 
              
              {
                  return(
                      
  
                      <>
                       
                     <div className="flex flex-col items-center">
          <img
            className="object-cover w-20 h-20 mb-2 rounded-full shadow"
            src={`${data.profile_pic}`}
            alt="Person"
          />
          <div className="flex flex-col items-center">
            <p className="text-lg font-bold text-white">{data.name}</p>
        <span className={`justify-items-center text-xs font-medium me-2 px-2.5 py-0.5 rounded ${data.role === 'Founder' ?  'bg-red-900 text-red-300' : data.role === 'Head Manager' ? 'bg-pink-900 text-pink-300' : data.role === 'Manager' ? 'bg-pink-900 text-pink-300' : data.role === 'Admin' ? 'bg-purple-900 text-purple-300' : data.role === 'J.R Admin' ? 'bg-purple-900 text-purple-300' : data.role === 'Mod' ? 'bg-indigo-900 text-indigo-300': data.role === 'J.R Mod' ? 'bg-indigo-900 text-indigo-300': data.role === 'Discord Admin' ? 'bg-yellow-900 text-yellow-300': 'bg-green-900 text-green-300'}`}>{data.role}</span>
          </div>
        </div>
        
  
                      </>
                  )
              })
          }
          </div>
          </div>
          </div>
        
    
  
  
  
  
    )
  }