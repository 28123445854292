import React, { useState, useEffect } from 'react';
import online from '../Images/online.png'
import offline from '../Images/offline.png'
import  ClipLoader from 'react-spinners/ClipLoader'

const ServerStatus = ({ servers }) => {
  const [statuses, setStatuses] = useState(null);
  const [playerCounts, setPlayerCounts] = useState(null);

  useEffect(() => {
    const checkServerStatuses = async () => {
      const newStatuses = {};
      const newPlayerCounts = {};
      for (const server of servers) {
        try {
          const response = await fetch(`https://api.mcsrvstat.us/2/${server.url}`);
          const data = await response.json();
          if (data.online) {
            newStatuses[server.name] = true;
            newPlayerCounts[server.name] = data.players.online;
          } else {
            newStatuses[server.name] = false;
            newPlayerCounts[server.name] = 0;
          }
        } catch (error) {
          newStatuses[server.name] = false;
          newPlayerCounts[server.name] = 0;
        }
      }
      setStatuses(newStatuses);
      setPlayerCounts(newPlayerCounts);
    };

    // Check server statuses initially and then every 10 seconds
    checkServerStatuses();
    const intervalId = setInterval(checkServerStatuses, 10000);

    // Cleanup function to clear interval
    return () => clearInterval(intervalId);
  }, [servers]);

  if (statuses === null || playerCounts === null) {
    return  <ClipLoader color="#fff"size={40}/> }


  return (
    <div>
    {servers.map(server => (
      <div
        key={server.name}
        className={`server-status ${statuses[server.name] ? 'online' : 'offline'}`}
      >
        <span className="server-name">{server.name}: </span>
        {statuses[server.name] ? <span style={{color: 'green', display: 'flex', alignItems: 'center', letterSpacing: '1px'}}> <img src={online} alt="" /> ONLINE <span className="player-count text-sm ml-2">{` (${playerCounts[server.name]} players)`}</span></span> : <span style={{color: 'red', display: 'flex', alignItems: 'center', letterSpacing: '1px'}}> <img src={offline} alt="" />  OFFLINE</span>}
        
      </div>
    ))}
  </div>
  );
};

export default ServerStatus;