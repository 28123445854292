import React, { useEffect, useState } from 'react'
import {doc , getDoc } from 'firebase/firestore'
import { db } from '../firebase'
import PropTypes from 'prop-types'
import { Link } from "react-router-dom";
import { auth } from '../firebase'
import { signOut } from "firebase/auth";
import {useDispatch} from 'react-redux'
import {setUser} from '../redux/usersSlice'
import Messages from '../Pages/Messages';
import {useSelector} from 'react-redux';
import {selectUsers} from '../redux/usersSlice';
// import { uid } from '../redux/usersSlice'


function Navbar(props) {
  const[data, setData] = useState([])
  const user = useSelector(selectUsers);

  const fetchData  = async () => {
    const docRef = doc(db, "users", `${user.currentUser.id}`);
    const docSnap = await getDoc(docRef)
    if (docSnap.exists) {
        setData(docSnap.data());
    }
    
    
    }
    useEffect(() =>
    {
        fetchData()
    },[]
    )

  const dispatch = useDispatch();
  function handleSignOut(){
    signOut(auth).then(() => {
      dispatch(setUser(null));
  })
    .catch((error) => {
  });
  }

  let role = `${data.role}`

  


  return (
    <div className='N-PC1'>
      <div>
      <header className="text-gray-600 body-font">
        <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
          <div
            className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0"
            to="/"
          >

            <span className="ml-3 text-xl text-white">Havenlands | Admin Panel</span>
          </div>
          <nav className="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-400	flex flex-wrap items-center text-base justify-center">
          <Link
              id="home"
              className={`mr-5 text-white hover:text-gray-800 px-2 py-1 rounded ${props.page === 'home' ? 'bg-slate-800 hover:bg-gray-300' : 'hover:bg-gray-100'} transition-colors duration-100`}
              to="/">
              Home
              </Link>
              <Link
              id="home"
              href={<Messages/>}
              className={`mr-5 text-white hover:text-white px-2 py-1 rounded ${props.page === 'Messages' ? 'bg-slate-800 hover:bg-gray-300' : 'hover:bg-gray-800'} transition-colors duration-100`}
              to="/messages">
              Messages
              </Link>
              <Link
              id="home"
              className={`mr-5 text-white hover:text-white px-2 py-1 rounded ${props.page === 'Applications' ? 'bg-slate-800 hover:bg-gray-300' : 'hover:bg-gray-800'} transition-colors duration-100`}
              to="/applications">
              Applications
              </Link>
              <Link
              id="home"
              className={`mr-5 text-white hover:text-white px-2 py-1 rounded ${props.page === 'Members' ? 'bg-slate-800 hover:bg-gray-300' : 'hover:bg-gray-800'} transition-colors duration-100`}
              to="/members">
              Members
              </Link>
              
             { data.admin ? <Link
              id="home"
              className={`mr-5 text-white hover:text-white px-2 py-1 rounded ${props.page === 'admin' ? 'bg-slate-800 hover:bg-gray-300' : 'hover:bg-gray-800'} transition-colors duration-100`}
              to="/administration">
              Administration-tools
              </Link> : <></> } 
              <div className={`flex items-center gap-4 hover:bg-slate-700/50 ${props.page === 'profile' ? 'bg-slate-700/50' : 'bg-none' }`} onClick={() => {window.location.replace("/profile")}} style={{cursor: 'pointer', borderRadius: "6px"}}>
    <img className="w-10 h-10 rounded-full" src={data.profile_pic} alt="avatar"/>
    <div className="font-medium dark:text-white">
        <div>{data.name}</div>
        <span className={`justify-items-center text-xs font-medium me-2 px-2.5 py-0.5 rounded 
        
          ${role === 'Founder' ?  'bg-red-900 text-red-300' : role === 'Head Manager' ? 'bg-pink-900 text-pink-300' :
          role === 'Manager' ? 'bg-pink-900 text-pink-300' :
          role === 'Admin' ? 'bg-purple-900 text-purple-300' :
          role === 'J.R Admin' ? 'bg-purple-900 text-purple-300' :
          role === 'Mod' ? 'bg-indigo-900 text-indigo-300': 
          role === 'J.R Mod' ? 'bg-indigo-900 text-indigo-300': 
          role === 'Discord Admin' ? 'bg-yellow-900 text-yellow-300':
          'bg-green-900 text-green-300'
        }

        `}>{data.role}</span>
    </div>
</div>
              
              


              
              <button onClick={handleSignOut}
            id="button"
            className="inline-flex mr-4 items-center bg-purple-500 border-0 py-1 pr-2 focus:outline-none rounded hover:scale-110 shadow-xl shadow-black transition duration-200 ease-in-out text-white mt-4 md:mt-0 ml-8"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="10"
              height="10"
              viewBox="0 0 16 16"
              className="mx-2 fill-white"
            >
              <path d="M7 6a1 1 0 0 0 0-2H5a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h2a1 1 0 0 0 0-2H6V6zM20.82 11.42l-2.82-4a1 1 0 0 0-1.39-.24 1 1 0 0 0-.24 1.4L18.09 11H10a1 1 0 0 0 0 2h8l-1.8 2.4a1 1 0 0 0 .2 1.4 1 1 0 0 0 .6.2 1 1 0 0 0 .8-.4l3-4a1 1 0 0 0 .02-1.18z"></path>
            </svg>
            
            Logout
          </button>
          

          </nav>
          <a target="_blank" rel="noreferrer" href="https://havenlands.tk/vote">

          </a>
        </div>
      </header>
    </div>
    </div>
    
  )
}

export default Navbar

Navbar.propTypes = {
    page: PropTypes.string,
    darkMode: PropTypes.bool,
    setDarkMode: PropTypes.func
  }
  
  Navbar.defaultProps = {
    page: 'home',
  }