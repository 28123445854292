import React, { useEffect, useState, Component  } from 'react'
import {doc , addDocs, collection, getDocs, orderBy, query, deleteDoc } from 'firebase/firestore'
import { db } from '../firebase'
import Navbar from '../Components/Navbar'
import swal from 'sweetalert';



export default function Message() {

    
  const[Name] = useState()
  const[discord] = useState()
  const[role] = useState()
  const[fetchData, setFetchData] = useState([])

  const dbbref = collection(db, "contactus_messages")
  const q = query(dbbref, orderBy('timestamp', 'desc'))

  // Fetching Data from FireStore

  const fetch = async () =>
  {
      const snapshot = await getDocs(q)
      const fetchdata = snapshot.docs.map((doc => ({id: doc.id, ...doc.data()})))
      setFetchData(fetchdata)
  }
  // const deletemsg = async () =>
  // {
  //   await deleteDoc(doc(db, "contactus_messages", `${doc.id}`));
  // }

 

  useEffect(() =>
  {
      fetch()

  },[]
  )
 



  

return (
  
  <div>
    <Navbar page="Messages"/>
    <div className="flex justify-center">
                <div className="my-4 text-2xl font-medium title-font mb-4 text-green-400">MESSAGES</div>
              </div>
 <div className="grid lg:grid-cols-1 sm:grid-cols-1">
      {
        
          fetchData.map((data)=> 
          
          
          {
            const deletemsg = async() =>{
              const willDelete = await swal({
                title: "Are you sure?",
                text: "Are you sure that you want to delete this message?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
              }
              
              );
              
              if (willDelete) {
                await deleteDoc(doc(db, "contactus_messages", `${data.id}`))
                fetch();
              }
              
             
            }
            
              return(
                  

                  <>
                  <div className="database">
                      
                  
                  <div class="max-w-100 bg-white border border-gray-200/ rounded-lg shadow dark:bg-gray-800/50 dark:border-gray-700">
                      
                  <div class="p-5">
                  <div className="grid lg:grid-cols-7 sm:grid-cols-1">
                      <h5 class="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">{data.name}</h5>
                      <p class="mb-3 text-sm font-normal text-gray-700 dark:text-gray-400">Minecraft Name: {data.mcuname}</p>
          
                      <p class="mb-3 text-sm font-normal text-gray-700 dark:text-gray-400">Discord Name: {data.discord}</p>
                    </div>
                      <p class="mb-3 text-sm font-normal text-gray-700 dark:text-gray-400">Message:</p>
                      <div className="nr px-4 pt-1">{data.msg}</div>
                      <br/>
                      <p class="mb-3 text-sm font-normal text-gray-700 dark:text-gray-400">Date: {data.timestamp.seconds}</p>
                      </div>
                      <button class="inline-flex flex flot flot-right items-center px-3 py-2 mx-5 my-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-blue-800" type="submit" onClick={() => {deletemsg()}}>Delete</button>                      
                       
                            
  </div>
</div>
                  </>
              )
              
          })
      }
      </div>
    





  </div>
)
}

