import React, { useEffect, useState } from 'react'
import { auth } from '../firebase'
import { onAuthStateChanged, signInWithEmailAndPassword, useDeviceLanguage } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {setUser} from '../redux/usersSlice'
import  ClipLoader from 'react-spinners/ClipLoader'
import logo from '../Images/logo.png'
import BrandNameDesign from '../Images/BrandNameDesign.png'

const Login = () => {

    const [loading, setLoading] = useState(false)
    useEffect(() => {
      setLoading(true)
      setTimeout(() =>{
        setLoading(false)
      }, 700)
    }, [])
  

    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [LoginType, setIsLoginType] = useState('login');
    const [userCredentials, SetUserCredentials] = useState({});
    const [error, setError] = useState('');
    
        onAuthStateChanged(auth, (user) => {
        if (user) {
         dispatch(setUser({id: user.uid, email: user.email}));
        
    // ...
  } else {
    dispatch(setUser(null));
  }

  

});

    function handleCredentials(e) {
        setError("")
        SetUserCredentials({...userCredentials, [e.target.name]: e.target.value});
        
    }
    function handleLogin (e) {
        e.preventDefault();
            signInWithEmailAndPassword(auth, userCredentials.email, userCredentials.password)     
    .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        setError(error.code)
    });
        }
    

  return (
    <>
    {
        loading ?
        <div className="loader">
        <ClipLoader color="#03e8fc"size={150}/> </div> :  <div  className='main'>
        {  error &&
                <div className="p-4 mb-4 text-sm text-red-800  bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
        <span className="font-medium">Havenlands  | Admin Panel: {error}</span>
        </div>
        }
  <div  className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <a href="#"  className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
          <img  className="w-8 h-8 mr-2" src={logo} alt="logo"/>
          <img  className="" src={BrandNameDesign} alt="logo"/>
      </a>
      <div  className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div  className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1  className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                  Login in to your account
              </h1>
              <div className="space-y-4 md:space-y-6" action="#">
                  <div>
                      <label for="email"  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                      <input onChange={(e)=>{handleCredentials(e)}} type="email" name="email" id="email"  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder=""  required=""/>
                  </div>
                  <div>
                      <label for="password"  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                      <input type="password"onChange={(e)=>{handleCredentials(e)}} name="password" id="password" placeholder="••••••••"  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required=""/>
                  </div>
                  <div  className="flex items-center justify-between">
                      
                  </div>
                  <button type="submit" onClick={(e)=>{handleLogin(e)}} className="w-full text-white bg-primary-600 hover:bg-slate-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Login in</button>
                  
              </div>
          </div>
      </div>
  </div>


  </div>
    
      }

    
  </>
  )
}




export default Login

