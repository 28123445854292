import React, { useEffect, useState } from 'react'
import {doc , addDocs, collection, getDocs, orderBy, query, updateDoc  } from 'firebase/firestore'
import { db } from '../firebase'
import { Link } from "react-router-dom";
import {useSelector} from 'react-redux';
import {selectUsers} from '../redux/usersSlice';

export default function Staffapps() {

    
    const user = useSelector(selectUsers);

    let currentUser = `${user.currentUser.id}`


    
    const[Name] = useState()
    const[discord] = useState()
    const[role] = useState()
    const[fetchData, setFetchData] = useState([])
    const propertyName = `${user.currentUser.id}`;
    const dbbref = collection(db, "applications_staff")
    const q = query(dbbref, orderBy('timestamp', 'desc'))

    // Fetching Data from FireStore

    const fetch = async () =>
    {
        const snapshot = await getDocs(q)
        const fetchdata = snapshot.docs.map((doc => ({id: doc.id, ...doc.data()})))
        setFetchData(fetchdata)
      
    }

    

    useEffect(() =>
    {
        fetch()
    },[]
    )
 
    
   

  return (
    <div>
   <h1 className='dh1'>Staff Rank Applications</h1>
   <div className="grid lg:grid-cols-4 sm:grid-cols-1">
        {
            fetchData.map((data)=> 
            
            {
                
                const propUser = data ? data[propertyName] : false;
                
                const markread = async () =>
                {
                    const readRef = doc(db, 'applications_staff', `${data.id}`);
                    updateDoc(readRef, { [currentUser]: true });
                    fetch();
              
                }

             
                return(
                    
                    

                    <>
                    { propUser ?
                    <></>
                 : <div className="database">
                        
                    
                 <div class="max-w-72 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                     <img class="rounded-t-lg container max-w-screen-lg mx-auto flex justify-center" src="https://i.pinimg.com/736x/e0/51/e8/e051e827977c1195c281e24ebf7bc913.jpg" alt="" />
 
                 <div class="p-5">
                     <h5 class="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">{data.name}</h5>
                     <p class="mb-3 text-sm font-normal text-gray-700 dark:text-gray-400">Discord Name: {data.discord}</p>
                     <p class="mb-3 text-sm font-normal text-gray-700 dark:text-gray-400">Role: {data.role}</p>
                     <button class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="submit" onClick={() => {window.location.replace(`/response/${data.id}`)}} >Open</button>
                     <button class="inline-flex items-center ml-3 px-3 py-2 text-sm font-medium text-center text-white bg-amber-300 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-amber-500 dark:hover:bg-amber-700 dark:focus:ring-amber-800" type="submit" onClick={() => {markread()  }} >Mark as Read </button>
                     </div>
                     
                      
                           
 </div>
 </div>   
                }
                    
                    
                    </>
                )
            })
        }
        </div>
      
  




    </div>
  )
}


