import React, { useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import 'firebase/compat/firestore';

import { db } from '../firebase'

const ImageUploader = ({ documentId }) => {
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState('');

  const handleChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const handleUpload = () => {
    if (!documentId) {
      console.error('Document ID is required.');
      return;
    }

    const storageRef = firebase.storage().ref(`images/${image.name}`);
    const uploadTask = storageRef.put(image);

    uploadTask.on(
      'state_changed',
      null,
      (error) => {
        console.error('Error uploading image: ', error);
      },
      () => {
        storageRef.getDownloadURL().then((url) => {
          setImageUrl(url);

          // Store image metadata in Firestore
          const firestore = db();
          firestore.collection('documents').doc(documentId).collection('users').add({
            name: image.name,
            url: url,
          });
        });
      }
    );
  };

  return (
    <div>
      <input type="file" onChange={handleChange} />
      <button onClick={handleUpload}>Upload</button>
      {imageUrl && <img src={imageUrl} alt="Uploaded" />}
    </div>
  );
};

export default ImageUploader;
