import React, { useEffect, useState } from 'react'
import Navbar from '../Components/Navbar'
import {useSelector} from 'react-redux';
import {selectUsers} from '../redux/usersSlice';
import {doc , getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../firebase'
import swal from 'sweetalert';
import ImageUploader from '../Components/ImageUploader';

export default function Profile() {
    const[data, setData] = useState([])
    const user = useSelector(selectUsers);
    const docRef = doc(db, "users", `${user.currentUser.id}`);
  
    const fetchData  = async () => {
      const docRef = doc(db, "users", `${user.currentUser.id}`);
      const docSnap = await getDoc(docRef)
      if (docSnap.exists) {
          setData(docSnap.data());
      }
      
      
      }
      useEffect(() =>
      {
          fetchData()
          
      },[]
      )
      let role = `${data.role}`
      
   
    {
        {   const openinput = async () =>
            {
               
                swal("Type something:", {
                    text: "Enter Username",
                  content: "input",
                  className: "bg",
                  buttons: [true, "Confirm"],
                    
                
                  
                  
                })
                
                .then((value) => {
                    if (value==null || value===false) {
                        ;
                          
                    } else if(!value){
                        swal("Type something:", {
                            icon: "warning",
                            text: "Username is required", 
                          className: "bg",
                          button: "Close",
                        })

                    } else {
                        swal({
                            title: "Done!",
                            text: `Your Username has been changed to ${value}`,
                            icon: "success",
                            
                            
                        
                          })
                          updateDoc(docRef, {
                              name: `${value}`
                            })
                            .then((updateDoc) => {
                                fetchData();
                            })
                    }
                    
                 
                      
                    
                });
                
                
            }
        
  return (
  
  
    <>
    <Navbar page="profile"/>

    <div>
        <div class="max-w-sm mx-auto bg-white dark:bg-gray-900 rounded-lg overflow-hidden shadow-lg">
            <div class="border-b px-4 pb-6">
                <div class="text-center my-4">
                    <img class="h-32 w-32 rounded-full border-4 border-white dark:border-gray-800 mx-auto my-4 profile-pic"
                        src={data.profile_pic} alt=""/>
                         {/* <div>
                            <h1>Image Uploader</h1>
                            <ImageUploader documentId={user.currentUser.id} />
                            </div> */}
                    <div class="flex items-center justify-center">
                        <h3 class="font-bold text-2xl text-gray-800 dark:text-white mb-1" >{data.name}</h3>    
                        
                    <svg className='fill-white size-6 mt-2 ml-2 cursor-pointer' onClick={() => {openinput()}}  xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 24 24">
                    <path d="M 13.550781 2.0019531 C 13.412183 1.9997829 13.332031 2.0058594 13.332031 2.0058594 A 0.750075 0.750075 0 0 0 12.773438 2.3300781 L 3.3632812 16.035156 A 0.750075 0.750075 0 0 0 3.234375 16.414062 L 3.0019531 20.205078 A 0.750075 0.750075 0 0 0 4.0625 20.931641 L 7.515625 19.353516 A 0.750075 0.750075 0 0 0 7.8222656 19.095703 L 17.230469 5.390625 A 0.750075 0.750075 0 0 0 17.332031 4.7519531 C 17.332031 4.7519531 16.976363 3.5163841 15.794922 2.7050781 C 14.908866 2.0966156 13.966577 2.0084638 13.550781 2.0019531 z M 13.730469 3.5839844 C 13.943458 3.5852744 14.366076 3.5422985 14.947266 3.9414062 C 15.527011 4.3395226 15.638307 4.7455582 15.716797 4.9453125 L 14.640625 6.5117188 L 12.65625 5.1484375 L 13.730469 3.5839844 z M 11.806641 6.3867188 L 13.792969 7.7480469 L 6.703125 18.074219 L 4.5742188 19.048828 L 4.71875 16.712891 L 11.806641 6.3867188 z M 10.75 19.5 A 0.75 0.75 0 0 0 10.75 21 A 0.75 0.75 0 0 0 10.75 19.5 z M 14.75 19.5 A 0.75 0.75 0 0 0 14.75 21 A 0.75 0.75 0 0 0 14.75 19.5 z M 18.75 19.5 A 0.75 0.75 0 0 0 18.75 21 A 0.75 0.75 0 0 0 18.75 19.5 z"></path>
                    </svg>
                    
                    </div>
                    <span className={`justify-items-center text-xs font-medium me-2 px-2.5 py-0.5 rounded 
                    ${role === 'Founder' ?  'bg-red-900 text-red-300' : 
                    role === 'Head Manager' ? 'bg-pink-900 text-pink-300' :
                    role === 'Manager' ? 'bg-pink-900 text-pink-300' :
                    role === 'Admin' ? 'bg-purple-900 text-purple-300' :
                    role === 'J.R Admin' ? 'bg-purple-900 text-purple-300' :
                    role === 'Mod' ? 'bg-indigo-900 text-indigo-300': 
                    role === 'J.R Mod' ? 'bg-indigo-900 text-indigo-300': 
                    role === 'Discord Admin' ? 'bg-yellow-900 text-yellow-300':
                    'bg-green-900 text-green-300'}
                    
                    `}>{data.role}</span>
                </div>    
            </div>
            <div class="px-4 py-4">
                <div class="flex gap-2 items-center text-gray-800 dark:text-gray-300 mb-4">
                    <svg class="h-6 w-6 text-gray-600 dark:text-gray-400" fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                        <path class=""
                            d="M12 12a5 5 0 1 1 0-10 5 5 0 0 1 0 10zm0-2a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm9 11a1 1 0 0 1-2 0v-2a3 3 0 0 0-3-3H8a3 3 0 0 0-3 3v2a1 1 0 0 1-2 0v-2a5 5 0 0 1 5-5h8a5 5 0 0 1 5 5v2z" />
                    </svg>
                    <span>Email:<strong class="text-black dark:text-white"> {data.email}</strong></span>
                </div>
                <div class="flex">
                    <div class="flex justify-end mr-2">

                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
    
  )
        }
}
}