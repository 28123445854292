import React, { useEffect, useState } from 'react'
import {doc , addDocs, collection, getDocs, orderBy, query, updateDoc  } from 'firebase/firestore'

import { db } from '../firebase'
import youtubeapp from '../Images/1red-and-white.png'
import {useSelector} from 'react-redux';
import {selectUsers} from '../redux/usersSlice';

export default function Ytapps() {
  const user = useSelector(selectUsers);

    let currentUser = `${user.currentUser.id}`

    const[Name] = useState()
    const[discord] = useState()
    const[subscribers] = useState()
    const[fetchData, setFetchData] = useState([])
    const propertyName = `${user.currentUser.id}`;

    const dbbref = collection(db, "applications_yt");
    const q = query(dbbref, orderBy('timestamp', 'desc'))
    

    // Fetching Data from FireStore

    const fetch = async () =>
    {
        const snapshot = await getDocs(q)
        const fetchdata = snapshot.docs.map((doc => ({id: doc.id, ...doc.data()})))
        setFetchData(fetchdata)
        
    }

    useEffect(() =>
    {
        fetch()
    },[]
    )

  return (
    <div>
    <h1 className='dh1'>Youtube Rank Applications</h1>
    <div className="grid lg:grid-cols-4 sm:grid-cols-1">
        {
            fetchData.map((data)=> 
            {
               
              
              const propUser = data ? data[propertyName] : false;
                
              const markread = async () =>
              {
                  const readRef = doc(db, 'applications_yt', `${data.id}`);
                  updateDoc(readRef, { [currentUser]: true });
                  fetch()
              }
                return(
                 <>
                  { propUser ? <></> :
                    <>
                    <div className="database">
                       
                    
                    <div className="max-w-72 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">                        <img className="rounded-t-lg container max-w-screen-lg mx-auto flex justify-center" src={youtubeapp} alt="" />

                    <div className="p-5">
                        <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">{data.name}</h5>
                        <p className="mb-3 text-sm font-normal text-gray-700 dark:text-gray-400">Discord Name: {data.discord}</p>
                        <p className="mb-3 text-sm font-normal text-gray-700 dark:text-gray-400">Subscribers: {data.subscribers}</p>
                        <button class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="submit" onClick={() => {window.location.replace(`/thread/${data.id}`)}} >Open</button>
                        <button class="inline-flex items-center ml-3 px-3 py-2 text-sm font-medium text-center text-white bg-amber-300 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-amber-500 dark:hover:bg-amber-700 dark:focus:ring-amber-800" type="submit" onClick={() => {markread()}} >Mark as Read </button>
                        </div>
                        
                      <div>
                        </div>  
                              </div>
                              
    </div>


                    </>
                }
                </>  
                        

                )
                
            })
            
        }
      </div>
  




    </div>
  )
}
