import { BrowserRouter, Routes, Route, Navigate  } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import Navbar from './Components/Navbar'
import { Outlet } from 'react-router-dom';
import Login from './Pages/Login';
import Dashboard from './Pages/Dashboard';
import {selectUsers} from './redux/usersSlice';
import {useSelector} from 'react-redux';
import Messages from './Pages/Messages';
import Applications from './Pages/Applications'
import Response from './Pages/Response';
import Thread from './Pages/Thread';
import Profile from './Pages/Profile';
import Members from './Pages/Members';
import ImageUploader from './Components/ImageUploader';
import NotFound from './Components/NotFound'
import Administration from './Pages/Administration';

function App() {
  const user = useSelector(selectUsers);
  return (
    
    <>  
      {user.currentUser ?
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Dashboard/>} />
          <Route path="/messages" element={<Messages/>} />
          <Route path="/applications/:id" element={<Applications/>} />
          <Route path="/response/:id" element={<Response/>} />
          <Route path="/thread/:id" element={<Thread/>} />
          <Route path="/profile" element={<Profile/>} />
          <Route path="/members" element={<Members/>} />
          <Route path="/uploadpfp" element={<ImageUploader/>} />
          <Route path="/applications" element={ <Navigate to="/applications/unread" /> } />
          <Route path="/administration" element={ <Administration/> } />
          <Route path="*" element={<NotFound />}/>
        </Routes>
      </BrowserRouter> :
        <Login/>
        }

        

      
        
    </>
  )
}

export default App;
